import { Button, Checkbox, Form, Input, message, Radio } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import hexToRgba from 'hex-to-rgba';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import {
  useCreatePollReplies,
  useCreatePollReply
} from '../../utils/store/poll';
import { GetBrandSettingFromRecoil } from '../../recoil/brandsetting';
import useUuid from '../../utils/hooks/useUuid';
import { useGetColorTest } from '../../utils/hooks/useGetColorTest';

const { TextArea } = Input;

const PollForm = ({
  activeQuestion,
  hasReplied,
  pollQuestionIds,
  setPollQuestionIds,
  readOnly
}) => {
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [showThankYou, setShowThankYou] = useState(false);
  const [space, setSpace] = useState(8);
  const [form] = Form.useForm();
  const inputRef = useRef();

  const uuid = useUuid();

  const screens = useBreakpoint();
  const { t } = useTranslation('common');
  const { accentColor = '#fff', pageTheme } = GetBrandSettingFromRecoil();
  const createPollReply = useCreatePollReply();
  const { createPollRepliesLoading, createPollReplies } =
    useCreatePollReplies();

  const colorBackground = useGetColorTest(
    pageTheme === 'dark' ? '#252525' : '#f7f7f7'
  );
  const colorText = useGetColorTest(accentColor);

  const { type, id, text, pollAnswers, allowMultiAnswers } = activeQuestion;

  const handleSubmit = async (values) => {
    if (!uuid) {
      message.error(t('poll-uuid'));
      return;
    }
    const { oneWord, unique, multiple } = values;
    let hasError = false;
    if (multiple) {
      const { error } = await createPollReplies({
        pollAnswerIds: multiple.map((answer) => answer),
        uuid
      });
      if (error) hasError = true;
    } else {
      const { error } = createPollReply({
        ...(oneWord && { text: oneWord, pollQuestionId: id }),
        ...(unique && { pollAnswerId: unique }),
        uuid
      });
      if (error) hasError = true;
    }
    if (!hasError) {
      setShowThankYou(true);
      setTimeout(() => {
        setPollQuestionIds([...pollQuestionIds, id]);
        setShowThankYou(false);
      }, 2000);
    } else {
      message.error('Il y a eu une erreur... Veuillez réessayer.');
    }
  };

  const handleChange = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      if (allowMultiAnswers) {
        setSelectedAnswers([...selectedAnswers, e.target.value]);
      } else {
        setSelectedAnswers([e.target.value]);
      }
    } else {
      setSelectedAnswers(
        selectedAnswers.filter((checkbox) => checkbox !== e.target.value)
      );
    }
  };

  useEffect(() => {
    if (screens.sm) {
      setSpace(8);
    } else {
      setSpace(4);
    }
  }, [screens]);

  return (
    <div className="poll__form">
      {(hasReplied || showThankYou) && !readOnly ? (
        <div className="poll__form__thanks" data-cy="poll-thank-you">
          <h2>{t('poll-thank-you')}</h2>
        </div>
      ) : (
        <>
          <h2 data-cy="pollQuestion-title">{text}</h2>
          <Form form={form} onFinish={handleSubmit}>
            <div className="form__container">
              {type.includes('oneWord') ? (
                <Form.Item
                  name="oneWord"
                  rules={[
                    {
                      required: true,
                      message: t('poll-mandatory-answer')
                    }
                  ]}
                  data-cy="oneWord-input"
                >
                  <div className="input__container">
                    <TextArea
                      ref={inputRef}
                      autoSize={{ minRow: 0 }}
                      showCount={true}
                      maxLength={20}
                      size="small"
                      data-cy="poll-textarea-input"
                      placeholder={t('poll-answer-placeholder')}
                      disabled={readOnly}
                    />
                  </div>
                </Form.Item>
              ) : (
                <>
                  {!allowMultiAnswers ? (
                    <Form.Item
                      name="unique"
                      labelCol={{ span: 1, offset: 1 }}
                      style={{ marginBottom: 0 }}
                      rules={[
                        {
                          required: true,
                          message: t('poll-mandatory-answer')
                        }
                      ]}
                    >
                      <Radio.Group>
                        {pollAnswers?.map((answer) => (
                          <div
                            key={answer?.id}
                            className={`radio__container ${
                              selectedAnswers.includes(answer.id)
                                ? 'checked'
                                : ''
                            }`}
                          >
                            <Radio
                              key={answer?.id}
                              onChange={handleChange}
                              value={answer.id}
                              data-cy="poll-radio-input"
                              disabled={readOnly}
                            >
                              <span className="answerText">{answer.text}</span>
                            </Radio>
                          </div>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  ) : (
                    <Form.Item
                      name="multiple"
                      style={{ marginBottom: space }}
                      rules={[
                        {
                          required: true,
                          message: t('poll-mandatory-answer')
                        }
                      ]}
                    >
                      <Checkbox.Group>
                        {pollAnswers?.map((answer) => (
                          <div
                            key={answer?.id}
                            className={`checkbox__container ${
                              selectedAnswers.includes(answer.id)
                                ? 'checked'
                                : ''
                            }`}
                          >
                            <Checkbox
                              key={answer?.id}
                              onChange={handleChange}
                              value={answer.id}
                              data-cy="poll-checkbox-input"
                              disabled={readOnly}
                            >
                              <span className="answerText">{answer.text}</span>
                            </Checkbox>
                          </div>
                        ))}
                      </Checkbox.Group>
                    </Form.Item>
                  )}
                </>
              )}
            </div>

            <Form.Item style={{ textAlign: 'center', margin: 0 }}>
              <Button
                type="primary"
                size={screens.sm ? 'default' : 'small'}
                loading={createPollRepliesLoading}
                htmlType="submit"
                data-cy="poll-submit-button"
                disabled={readOnly}
              >
                {t('send')}
              </Button>
            </Form.Item>
          </Form>
        </>
      )}

      <style jsx>{`
        .poll__form {
          min-width: 100%;
          margin: 0 auto;
        }
        .form__container {
          margin: ${space * 2}px 0;
        }
        .poll__form h2 {
          margin: 0;
        }
        .checkbox__container,
        .radio__container {
          border: 2px solid ${pageTheme === 'light' ? '#eee' : '#666'};
          border-radius: 4px;
          background: rgba(
            ${pageTheme === 'light' ? '255, 255, 255' : '0, 0, 0'},
            0.8
          );
          transition: border 0.2s ease-in-out, background 0.2s ease-in-out;
          padding: ${space}px;
          margin: 0 0 ${space}px;
        }
        .poll__form .checkbox__container:last-child,
        .poll__form .radio__container:last-child {
          margin-bottom: 0;
        }
        .checkbox__container:hover,
        .radio__container:hover,
        .checkbox__container.checked,
        .radio__container.checked {
          background: rgba(
            ${pageTheme === 'light' ? '255, 255, 255' : '0, 0, 0'},
            1
          );
        }
        .checkbox__container.checked,
        .radio__container.checked {
          border-color: ${colorBackground};
        }
        .answerText {
          color: ${pageTheme === 'light'
            ? '255, 255, 255, 0.75'
            : '0, 0, 0, 0.75'};
        }
      `}</style>
      <style jsx global>{`
        .poll__form label {
          font-size: ${screens.xl ? 14 : 10}px;
          color: ${pageTheme === 'light' ? '#333' : '#fff'};
          line-height: 1.2;
          font-weight: 300;
          width: 100%;
        }
        .input__container .ant-input {
          font-size: 22px !important;
          text-align: center !important;
        }
        .poll__form .ant-radio-group,
        .poll__form .ant-checkbox-group {
          width: 100%;
        }
        .poll__form .ant-radio-checked::after,
        .poll__form .ant-radio-checked .ant-radio-inner,
        .poll__form .ant-radio-wrapper:hover .ant-radio,
        .poll__form .ant-radio-input:focus + .ant-radio-inner {
          border-color: ${colorBackground};
        }
        .poll__form .ant-radio-inner::after {
          background-color: ${colorBackground};
        }
        .poll__form .ant-checkbox-inner {
          width: ${screens.xl ? 24 : 20}px !important;
          height: ${screens.xl ? 24 : 20}px !important;
        }
        .poll__form .ant-radio-inner,
        .poll__form
          .ant-radio-wrapper.ant-radio-wrapper-in-form-item
          input[type='radio'] {
          width: ${screens.xl ? 20 : 16}px !important;
          height: ${screens.xl ? 20 : 16}px !important;
        }
        .poll__form .ant-checkbox-inner:after {
          width: 8px;
          height: 15px;
        }
        .poll__form .ant-radio-inner:after {
          width: ${screens.xl ? 24 : 20}px;
          height: ${screens.xl ? 24 : 20}px;
          margin-top: -${screens.xl ? 12 : 10}px;
          margin-left: -${screens.xl ? 12 : 10}px;
        }
        .poll__form .ant-checkbox-checked .ant-checkbox-inner:after {
          border-width: 3px !important;
        }
        .poll__form .ant-checkbox-wrapper,
        .poll__form .ant-radio-wrapper {
          display: flex;
          align-items: center;
        }
        .poll__form .ant-checkbox,
        .poll__form .ant-radio {
          margin-right: 8px;
          top: 0;
        }
        .poll__form .ant-btn {
          width: 100%;
          color: ${colorText};
          font-size: ${screens.xl ? 22 : 16}px;
          height: ${screens.xl ? 48 : 32}px;
          font-weight: 600;
          border-radius: 4px;
          background: ${accentColor};
          background: radial-gradient(
            circle,
            ${hexToRgba(accentColor, 1)},
            ${hexToRgba(accentColor, 0.8)}
          );

          background: -moz-radial-gradient(
            circle,
            ${hexToRgba(accentColor, 1)},
            ${hexToRgba(accentColor, 0.8)}
          );

          background: -webkit-radial-gradient(
            circle,
            ${hexToRgba(accentColor, 1)},
            ${hexToRgba(accentColor, 0.8)}
          );

          filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#BEB15B", endColorstr="#D5CFAC", GradientType=1 );
          backdrop-filter: brightness(0.5);
        }
        .poll__form .ant-btn[disabled] {
          background: #f5f5f5;
        }
        .poll__form textarea {
          resize: none;
          border: 2px solid ${pageTheme === 'light' ? '#eee' : '#666'};
          padding: 8px;
        }
        .poll__form .ant-form-item-explain-error {
          margin: 10px 0 0 0;
          text-align: center;
          font-weight: 500;
        }
        .ant-radio-disabled + span {
          color: #8c8c8c;
        }
        .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
        .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
          background: transparent;
        }
      `}</style>
    </div>
  );
};

export default PollForm;
