import { CloseOutlined } from '@ant-design/icons';
import { Grid, Tooltip } from 'antd';
import { useTranslation } from 'next-i18next';
import { parseCookies, setCookie } from 'nookies';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { activeQuestionState } from '../../recoil/poll';
import { getPosition } from '../../utils/functions/player';
import { getVODActiveQuestion } from '../../utils/functions/poll';
import useInterval from '../../utils/hooks/useInterval';
import { GetPoll, GetPollCurrentQuestion } from '../../utils/store/poll';
import { GET_UPDATED_POLL } from '../../utils/subscription/poll';
import CustomError from '../CustomError';
import LoaderSpin from '../LoaderSpin';
import MountAnimation from '../MountAnimation';
import PollButton from './PollButton';
import PollForm from './PollForm';
import PollResults from './PollResults';
import { GetBrandSettingFromRecoil } from '../../recoil/brandsetting';

const { useBreakpoint } = Grid;

const Poll = ({ pollId, isEmbed, isMuted, contentType }) => {
  const [hasReplied, setHasReplied] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [pollQuestionIds, setPollQuestionIds] = useState([]);
  const [position, setPosition] = useState(0);

  const [activeQuestion, setActiveQuestion] =
    useRecoilState(activeQuestionState);

  const {
    poll: pollLive,
    subscribeToMore,
    loading: loadingLive,
    error: errorLive
  } = GetPollCurrentQuestion(pollId, contentType !== 'live');

  const { pageTheme} = GetBrandSettingFromRecoil();

  const {
    poll: pollVod,
    loading: loadingVod,
    error: errorVod
  } = GetPoll(pollId, contentType !== 'media');

  const { t } = useTranslation();
  const screens = useBreakpoint();

  const handleClose = () => {
    setIsOpen(false);
  };

  //store the pollCurrentQuestion in activeQuestion state
  useEffect(() => {
    if (!pollLive) return;
    if (pollLive?.pollCurrentQuestion) {
      setActiveQuestion(pollLive.pollCurrentQuestion);
    } else {
      setActiveQuestion(null);
    }
  }, [pollLive]);

  useEffect(() => {
    if (!pollVod?.pollQuestions) return;
    const activeQuestionTmp = getVODActiveQuestion(
      pollVod?.pollQuestions,
      'question',
      position
    );
    if (activeQuestionTmp) {
      setActiveQuestion((prev) => {
        if (prev?.id === activeQuestionTmp.id) return prev;
        return activeQuestionTmp;
      });
    } else {
      const activeQuestionResultTmp = getVODActiveQuestion(
        pollVod?.pollQuestions,
        'result',
        position
      );
      if (activeQuestionResultTmp) {
        setActiveQuestion((prev) => {
          if (prev?.id === activeQuestionResultTmp.id) return prev;
          return {
            ...activeQuestionResultTmp,
            questionIsPublished: false,
            answerIsPublished: true
          };
        });
      } else {
        setActiveQuestion(null);
      }
    }
  }, [pollVod, position]);

  useInterval(() => {
    try {
      const currentPosition = getPosition();
      setPosition(currentPosition * 1000);
    } catch {}
  }, 500);

  //attach the subscribeToMore to the poll
  useEffect(() => {
    if (!subscribeToMore) return;

    const unsubscribe = subscribeToMore({
      document: GET_UPDATED_POLL,
      variables: {
        pollId
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const updatedPoll = subscriptionData.data.poll;
        return Object.assign({}, prev, {
          poll: {
            ...prev.poll,
            pollCurrentQuestion: updatedPoll.pollCurrentQuestion
          }
        });
      }
    });

    return () => {
      unsubscribe();
    };
  }, [subscribeToMore]);

  //store the already replied question in pollQuestionIds array at the first load of the page
  useEffect(() => {
    const pollQuestionIds = parseCookies()['pollQuestionIds'] || [];
    pollQuestionIds.length && setPollQuestionIds(pollQuestionIds);
  }, []);

  //set the hasReplied state
  useEffect(() => {
    if (!pollQuestionIds.length) return;
    setCookie(null, 'pollQuestionIds', JSON.stringify(pollQuestionIds), {
      sameSite: 'None',
      secure: true,
      maxAge: 3 * 60 * 60
    });
    setHasReplied(pollQuestionIds.includes(activeQuestion?.id));
  }, [pollQuestionIds, activeQuestion]);

  //open the form or result anytime something new is published
  useEffect(() => {
    if (isEmbed) return setIsOpen(true);
    if (
      (activeQuestion?.questionIsPublished && !hasReplied) ||
      activeQuestion?.answerIsPublished ||
      contentType === 'media'
    ) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [activeQuestion, hasReplied]);

  useEffect(() => {}, [activeQuestion]);

  if (errorLive || errorVod) {
    return (
      <CustomError
        errorCode={'Erreur 500'}
        errorMessage={
          'Il y a un problème sur cette page. Nos équipes sont en train de la résoudre. vous pouvez recharger la page ou en essayer une autre.'
        }
      />
    );
  }

  if (loadingLive || loadingVod) return <LoaderSpin />;

  if (!activeQuestion) return <></>;

  return (
    <div className="poll">
      {!isEmbed && !isOpen && (
        <PollButton setIsOpen={setIsOpen} isMuted={isMuted} />
      )}

      {isOpen && (
        <div className={isEmbed ? 'poll__embed' : 'poll__overlay'}>
          {/* {isEmbed && !activeQuestion ? (
            <h2>{t('poll-no-question')}Il n'y a pas de question pour le moment.</h2>
          ) : ( */}
          <>
            {!isEmbed && (
              <div className="poll__close" onClick={handleClose}>
                <Tooltip title={t('poll-close')} placement="right">
                  <CloseOutlined style={{ fontSize: 16 }} />
                </Tooltip>
              </div>
            )}
            <MountAnimation>
              {activeQuestion?.answerIsPublished ? (
                <PollResults
                  text={activeQuestion.text}
                  type={activeQuestion?.type}
                  words={activeQuestion?.words}
                  pollAnswers={activeQuestion?.pollAnswers}
                />
              ) : (
                <PollForm
                  activeQuestion={activeQuestion}
                  pollQuestionIds={pollQuestionIds}
                  hasReplied={hasReplied}
                  setPollQuestionIds={setPollQuestionIds}
                  readOnly={contentType === 'media'}
                />
              )}
            </MountAnimation>
          </>
          {/* )} */}
        </div>
      )}

      <style jsx>{`
        .poll {
          font-family: 'Poppins', sans-serif;
          height: 100%;
        }
        .poll__embed {
          height: 100%;
          // padding: 24px;
        }
        .poll__close {
          position: absolute;
          top: 0;
          left: 0;
          padding: 8px;
          cursor: pointer;
          z-index: 1;
        }
        .poll__overlay {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          max-width: 700px;
          border-radius: 8px;
          background: rgba(
            ${pageTheme === 'light' ? '255, 255, 255' : '0, 0, 0'},
            0.85
          );
          backdrop-filter: blur(3px);
          z-index: 100;
          padding: ${isEmbed ? 0 : screens.xl ? 24 : 8}px;
        }
      `}</style>

      <style jsx global>{`
        h2 {
          font-size: ${screens.xl ? 20 : 14}px;
          line-height: 1.3;
          font-weight: 600;
          text-align: center;
          margin: 0 0 ${screens.xl ? 16 : 8}px;
        }
      `}</style>
    </div>
  );
};

export default Poll;
